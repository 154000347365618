import * as React from "react";
import { useState, useEffect } from "react";
import "../css/style.css";
import { Link, useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Helmet from "react-helmet";

import at from "../images/at.svg";

export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
  `)

  const [x, setX] = useState()
  const [y, setY] = useState()

  useEffect(
    () => {
      const update = (e) => {
        setX(Math.min(e.x,200))
        setY(e.y + (document.body.scrollTop ? document.body.scrollTop : document.documentElement.scrollTop))
      }
      window.addEventListener('mousemove', update)
      window.addEventListener('touchmove', update)
      return () => {
        window.removeEventListener('mousemove', update)
        window.removeEventListener('touchmove', update)
      }
    },
    [setX, setY]
  );  

  return (
    <div className="layout">
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{data.site.siteMetadata.title}</title>
      </Helmet>
      {/*<Link id="promoHeader" to="/store">Order before Dec. 21 for guaranteed Christmas delivery!</Link>*/}
      <header id="siteHeader">
        <Link className="homepage" to="/">
          <div className="prong">
            <StaticImage className="blink" src="../images/icon_blink.png" alt="A handsome pronghorn" />
            <StaticImage className="open" src="../images/icon.png" alt="A handsome pronghorn" />
          </div>
          <div className="headerTitle">
            <h1 className="name">Alex McPhee</h1>
            <h1 className="workshop">Pronghorn Maps</h1>
          </div>
        </Link>
        <div className="headerLink">
          <Link className="longform" to="/alberta">Alberta</Link>
          <Link className="abbrev" to="/alberta">Alta.</Link>
          <Link className="longform saskLink" to="/saskatchewan"><div>Saskatchewan</div><div>2nd edition out now!</div></Link>
          <Link className="abbrev" to="/saskatchewan">Sask.</Link>
          <Link to="/store">Store</Link>
          <Link className="longform" to="/freelance">Freelance</Link>
          <Link className="abbrev" to="/freelance">Hire</Link>
        </div>
      </header>
      {children}
      <footer>
        <div className="contact">
          <StaticImage className="stamp" src="../images/stamp.png" alt="A postage stamp" />
          <div className="tooltip tooltip-bottom-left" style={{"position": "absolute", "left" : x, "top" : y}}>Nowadays you'll need at least two pronghorn stamps to deliver anything.</div>
          <div>
            <div>alex<img src={at} alt="@" className="at" />awmcphee.ca</div>
            <div className="address">Box 201, Val Marie SK, S0N 2T0</div>
          </div>
        </div>
        <div className="headerLink">
          <Link className="longform" to="/policy#shipping">Shipping</Link>
          <Link className="longform" to="/policy#refund">Refunds</Link>
          <Link className="longform" to="/policy#privacy">Privacy</Link>
          <Link className="abbrev" to="/policy">Site Policy</Link>
        </div>
      </footer>
    </div>
  );
}
